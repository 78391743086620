<script lang="ts" setup>
import 'vue3-carousel/dist/carousel.css'

const emit = defineEmits<{
  (e: 'scrollToContact'): void
}>()

const carousel = ref()

const references = [
  {
    title: 'Fastchange',
    img: '/img/fastchange.webp',
    href: 'https://fastchange.cz',
  },
  {
    title: 'Czech Portal',
    img: '/img/czechportal.webp',
    href: 'https://czechportal.cz',
  },
  {
    title: 'Alpacaex',
    img: '/img/alpacaex.webp',
    href: 'https://alpacaex.io',
  },
  {
    title: 'GCEX',
    img: '/img/gcex.webp',
    href: 'https://gcex.cz',
  },
  {
    title: 'You.cz',
    img: '/img/youcz.webp',
    href: 'https://you.cz',
  },
]

const breakpoints = {
  // 0px and up
  0: {
    itemsToShow: 1,
    snapAlign: 'start',
  },
  // 700px and up
  700: {
    itemsToShow: 2,
    snapAlign: 'start',
  },
  // 1024 and up
  1024: {
    itemsToShow: 2,
    snapAlign: 'start',
  },
  // 1280 and up
  1280: {
    itemsToShow: 3,
    snapAlign: 'start',
  },
}
</script>

<template>
  <div relative px-20px>
    <ClientOnly>
      <Carousel ref="carousel" :wrap-around="true" :autoplay="2500" :transition="500" :pause-autoplay-on-hover="true" :breakpoints="breakpoints" cursor-grab>
        <Slide v-for="item in references" :key="item.href" p-20px>
          <div flex flex-col gap-16px items-start border-1 border-neutral-300 fubex-rounded-md overflow-hidden pb-16px bg-neutral-100 fubex-shadow-sm>
            <div>
              <img :src="`${item.img}`" alt="">
            </div>
            <h3 heading4 font-700 mt-16px flex justify-center items-center w-full>
              {{ item.title }}
            </h3>
            <div flex gap-12px flex-col justify-center items-center w-full>
              <FButton :data-test="getCypressId('describe')" name="whitelabel-describe" variant="primary" @click="emit('scrollToContact')">
                Poptat podobné řešení
              </FButton>
              <FButton :data-test="getCypressId('redirect')" name="whitelabel-redirect" variant="text" basic-link target="_blank" suffix-icon="i-fubex-external-link" :to="item.href">
                Přejít na {{ item.href.replace('https://', '') }}
              </FButton>
            </div>
          </div>
        </Slide>
      </Carousel>
    </ClientOnly>
    <div absolute top="50%" left--10px md:left--20px>
      <FButton name="prev-slide" variant="secondary" :icon-only="true" prefix-icon="i-fubex-chevron-left-two" @click="carousel.prev()" />
    </div>
    <div absolute top="50%" right--10px md:right--20px>
      <FButton name="next-slide" variant="secondary" :icon-only="true" prefix-icon="i-fubex-chevron-right-two" @click="carousel.next()" />
    </div>
  </div>
</template>
